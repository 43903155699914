import { useEffect, useState } from 'react';
import Image from 'next/image'
import { GoogleLoginButton } from "react-social-login-buttons";
import Header from '../components/Header';
import { XCircleIcon } from '@heroicons/react/outline';
import { supabase } from '../utils/supabaseClient';
import { server } from '../config';

const LoginPage = () => {

    const handleLogin = async () => {
        const { error } = await supabase.auth.signIn(
            {
				provider: 'google'
			},
			{ redirectTo: `${server}/admin?refresh=true` }
		)
		if (error) {
			alert(error.message);
		}
    }
    return (
        <>
            <Header title="Login" description="Login to thelegalcanary.com to get access to patterns and tools" keywords="login" />
            <div className="bg-gradient-to-b from-white  to-zinc-100 text-sm text-gray-600 h-screen flex flex-col justify-center items-center">
                <div>
                    <p className="mb-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">Login</p>
                </div>
                <p className="uppercase tracking-wide">Select a sign-in provider</p>
                <div className='text-center max-w-lg'>
                    <GoogleLoginButton style={{ borderRadius: "1px", boxShadow: 'none', border: 'lightgray 1px solid' }} onClick={handleLogin} />
                </div>
            </div>
            {/* <div className="min-h-screen flex items-center justify-center bg-sky-200">
                <div className='bg-white w-fit mx-auto rounded-lg shadow p-5 md:p-20'>
                    <div>
                        <p className="text-2xl text-center tracking-tight leading-10 font-extrabold text-gray-900">Login</p>
                        <div>
                            <p className='text-center  text-gray-600 italic'>Select a sign-in provider</p>
                        </div>
                    </div>
                    <div className='text-center max-w-lg'>
                        <GoogleLoginButton style={{ borderRadius: "1px", boxShadow: 'none', border: 'lightgray 1px solid' }} onClick={handleLogin} />
                    </div>
                </div>
            </div> */}
        </>

    );
}

export default LoginPage;